import React, { useCallback, useMemo, useRef } from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';

import CategoryDropdown from '../CategoryDropdown';

import imgLogo from 'assets/images/Logo.png';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Link, { LinkProps } from 'components/atoms/Link';
import Container from 'components/organisms/Container';
import BadgeItem from 'components/organisms/Header/BadgeItem';
import useNavigatePrivate from 'hooks/useNavigatePrivate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeModalLogin } from 'store/system';
import PATHS from 'utils/paths';

export interface NavItemProps extends LinkProps {
  label?: React.ReactNode;
}

interface HeaderBottomProps {
  logoImage?: string;
}

const HeaderBottom: React.FC<HeaderBottomProps> = () => {
  const refSearch = useRef<HTMLInputElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const carts = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.auth.profile);
  const menu = useMemo(() => [
    {
      href: PATHS.HOME,
      label: 'TRANG CHỦ',
      isPrivate: true,
    },
    {
      href: PATHS.ALL_PRODUCT,
      label: 'SẢN PHẨM',
      isPrivate: true
    },
    // {
    //   href: PATHS.NEWS_PROMOTIONS,
    //   label: 'TIN TỨC - KHUYẾN MÃI',
    // },
    // {
    //   href: PATHS.NEWS_WHOLESALE_CUSTOMERS,
    //   label: 'TIN TỨC VÀ SỰ KIỆN',
    // },
    // {
    //   href: PATHS.FLOWER_CARE,
    //   label: 'CHĂM SÓC HOA',
    // },
  ], []);

  const keyword = useMemo(() => searchParams.get('keyword') || '', [searchParams]);
  const handleChangeKeyword = useCallback(() => {
    if (!refSearch.current) return;
    if (refSearch.current.value) {
      searchParams.set('keyword', refSearch.current.value);
    } else {
      searchParams.delete('keyword');
    }
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleSearch = useCallback(() => {
    if (pathname === PATHS.ALL_PRODUCT) {
      handleChangeKeyword();
      return;
    }
    navigate({
      pathname: PATHS.ALL_PRODUCT,
      search: refSearch.current?.value ? `?keyword=${refSearch.current?.value}` : undefined
    });
  }, [handleChangeKeyword, navigate, pathname]);

  const { navigatePrivate } = useNavigatePrivate();

  return (
    <Container>
      <div className="o-header_elements">
        <div className="o-header_elements_logo u-pb-8">
          <Link href={PATHS.HOME}>
            <Image
              size="contain"
              ratio="176x54"
              alt="logo"
              src={imgLogo}
            />
          </Link>
          <div className="u-mt-8">
            <CategoryDropdown />
          </div>
        </div>
        <div className="o-header_elements_panel">
          <div className="o-header_elements_wrapper">
            <div className="o-header_elements_search">
              <Input
                ref={refSearch}
                defaultValue={keyword}
                isSearch
                placeholder="Tìm kiếm sản phẩm"
                handleClick={handleSearch}
                onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
              />
            </div>
            <BadgeItem
              text="Giỏ hàng"
              iconName="shoppingCartGreen"
              badgeNumber={carts.cart?.cart_items?.filter((x) => x.type === 'product').length}
              onClick={() => navigatePrivate(PATHS.CART)}
            />
          </div>
          <div className="o-header_elements_navigation">
            {menu.map(({ label, isPrivate, ...navProps }, navIdx) => (
              <Link
                onClick={(e) => {
                  if (!profile && isPrivate) {
                    e.preventDefault();
                    dispatch(changeModalLogin({
                      isOpenLogin: true,
                      redirectTo: navProps.href,
                    }));
                  }
                }}
                {...navProps}
                className="o-header_elements_link"
                key={`${navIdx.toString()}`}
              >
                {label}
              </Link>
            ))}
            <Link className="o-header_elements_link" href="https://b2b.dlhf.vn" target="_blank">ĐẶT HÀNG THƯỜNG</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeaderBottom;
